<template>
  <div class="syllavus-A-cotainer">
    <SyllabusPage :wordList="wordListData" :vowelsToLearn="vowelsToLearn" :isPageA="true" />
  </div>
</template>

<script>
import SyllabusPage from "@/components/Course/CoursePage/SyllabusPage";

export default {
  name: "SyllabusPage-lesson-1-A",
  data() {
    return {
      vowelsToLearn: "b p m f",
      wordListData: [
        {
          title: "我会认",
          isUse: false,
          content: [
            {
              pinyin: "fāyīn kǒuxíng",
              hanzi: "发音口型",
              symbol: "",
              group: 0,
              index: 0,
              symbolType: 0,
              fullWidth: 1
            },
          {
              pinyin: "fāyīn fāngfǎ",
              hanzi: "发音方法",
              symbol: "",
              group: 0,
              index: 1,
              symbolType: 0,
              fullWidth: 1
            }
          ],
        },
        {
          title: "我会读",
          isUse: true,
          contentList: [
            [
              {
                pinyin: "biāozhǔn dúyīn",
                hanzi: "标准读音",
                symbol: "",
                group: 1,
                line: 0,
                index: 0,
                symbolType: 0,
              }
            ],
            [
            {
                pinyin: "zhǔnquè",
                hanzi: "准确",
                symbol: "",
                group: 2,
                line: 1,
                index: 0,
                symbolType: 0,
              },
            {
                pinyin: "de",
                hanzi: "的",
                symbol: "",
                group: 2,
                line: 1,
                index: 1,
                symbolType: 0,
              },
            {
                pinyin: "dúchū",
                hanzi: "读出",
                symbol: "",
                group: 2,
                line: 1,
                index: 2,
                symbolType: 0,
              },
            {
                pinyin: "xiāngguān",
                hanzi: "相关",
                symbol: "",
                group: 2,
                line: 1,
                index: 3,
                symbolType: 0,
              },
              {
                pinyin: "yīnjié",
                hanzi: "音节",
                symbol: "",
                group: 2,
                line: 1,
                index: 4,
                symbolType: 0,
              }, 
            ]
          ],
        },
        {
          title: "我会写",
          isUse: false,
          content: [

            {
              pinyin: "ànzhào",
              hanzi: "按照",
              symbol: "",
              group: 2,
              index: 0,
              symbolType: 0,
            },
            {
              pinyin: "guīzé ",
              hanzi: "规则",
              symbol: "",
              group: 2,
              index: 1,
              symbolType: 0,
            },

            {
              pinyin: "zhèngquè ",
              hanzi: "正确",
              symbol: "",
              group: 2,
              index: 2,
              symbolType: 0,
            },
            {
              pinyin: "shūxiě ",
              hanzi: "书写",
              symbol: "",
              group: 2,
              index: 3,
              symbolType: 0,
            },
            {
              pinyin: "pīnyīn ",
              hanzi: "拼音",
              symbol: "",
              group: 2,
              index: 4,
              symbolType: 0,
            },
            {
              pinyin: "zìmǔ",
              hanzi: "字母",
              symbol: "",
              group: 2,
              index: 5,
              symbolType: 0,
            }
          ],
        },
      ],
    };
  },
  components: {
    SyllabusPage,
  },
};
</script>

<style lang="scss" scoped>
.syllavus-A-cotainer {
  width: 100%;
  height: 100%;
}
</style>
<!--
 * @Author: your name
 * @Date: 2022-03-11 13:19:36
 * @LastEditTime: 2022-03-15 15:28:38
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nihaokidsNew-front\src\views\Course\Level1\Chapter3\Lesson23\1_Lesson.vue
-->
<template>
  <div class="lesson-container">
    <StartStop :position="positionStr" :pageInfo="pageInfoObj" :chapterNo="3" />
  </div>
</template>

<script>
import StartStop from "@/components/Course/CoursePage/StartStop";
export default {
  data() {
    return {
      positionStr: "lesson",
      pageInfoObj: {
        pinyin: "Wǒ de jiā",
        hanzi: "我的朋友",
        english: "My friend",
        sound: require("@/assets/audio/L1/1-Chapter/chapter-3-title.mp3"),
      },
    };
  },
  components: {
    StartStop,
  },
};
</script>

<style lang="scss" scoped>
.lesson-container {
  width: 100%;
  height: 100%;
}
</style>
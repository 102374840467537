<!--
 * @Author: your name
 * @Date: 2022-03-15 09:05:33
 * @LastEditTime: 2022-03-15 15:37:59
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nihaokidsNew-front\src\views\Course\Level1\Chapter3\Lesson23\12_RecognitionPage_l.vue
-->
<template>
  <div class="recognition-page-Two">
    <RecognitionPage :backgroundImage="bgImg_hao" :wordObj="wordDetail_hao" />
  </div>
</template>

<script>
import RecognitionPage from "@/components/Course/CoursePage/RecognitionPage";
export default {
  data() {
    return {
      bgImg_hao: require("@/assets/img/14-Pinyin/chapter-03/pinyin-l-lips.svg"),
      wordDetail_hao: {
        pinName: "",
        chineseName: "",
        englishName: "l",
        songUrl: require("@/assets/audio/L1/2-Pinyin/l.mp3"),

      },
    };
  },
  components: {
    RecognitionPage,
  },
};
</script>

<style lang="scss" scoped>
.recognition-page-Two {
  width: 100%;
  height: 100%;
}
</style>
<!--
 * @Author: your name
 * @Date: 2022-03-11 13:19:36
 * @LastEditTime: 2022-03-15 16:06:12
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nihaokidsNew-front\src\views\Course\Level1\Chapter3\Lesson23\21_PinyinSummary.vue
-->
<template>
  <div class="rank-map-page-one">
    <PinyinSummary :pinyinImgList="pinyinImgList" :bgImg="bgImg" :lesson="lesson"/>
  </div>
</template>

<script>
import PinyinSummary from "@/components/Course/CoursePage/PinyinSummary";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/19-Book-review/lesson-23-background.svg"),
      pinyinString: ["d", "t", "n", "l"],
      lesson:23
    };
  },
  computed: {
    pinyinImgList() {
      let imgArr = [];
      for (let i = 1; i <= 4; i++) {
        const element = require(`@/assets/img/19-Book-review/lesson-23-review-${
          this.pinyinString[i - 1]
        }.svg`);
        imgArr.push(element);
      }
      return imgArr;
    },
  },
  components: {
    PinyinSummary,
  },
};
</script>

<style lang="scss" scoped>
.rank-map-page-one {
  width: 100%;
  height: 100%;
}
</style>
<!--
 * @Author: your name
 * @Date: 2022-03-15 09:05:33
 * @LastEditTime: 2022-03-15 15:36:51
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \nihaokidsNew-front\src\views\Course\Level1\Chapter3\Lesson23\8_RecognitionPage_t.vue
-->
<template>
  <div class="recognition-page-one">
    <RecognitionPage :backgroundImage="bgImg" :wordObj="wordDetail" />
  </div>
</template>

<script>
import RecognitionPage from "@/components/Course/CoursePage/RecognitionPage";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/14-Pinyin/chapter-03/pinyin-t-lips.svg"),
      wordDetail: {
        lesson7: true,
        pinName: "",
        chineseName: "",
        englishName: "t",
        songUrl: require("@/assets/audio/L1/2-Pinyin/t.mp3"),
      },
    };
  },
  components: {
    RecognitionPage,
  },
};
</script>

<style lang="scss" scoped>
.recognition-page-one {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>